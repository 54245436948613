import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TodayIcon from '@mui/icons-material/Today';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {endOfToday, getMonth, getYear, startOfDay, sub} from 'date-fns';
import {Dispatch, SetStateAction} from 'react';
import {BoxCentered} from '../common';

interface FilterPeriodSelectProps {
  onSelect: Dispatch<SetStateAction<VolumeFilter>>;
  selected: VolumeFilter;
  visibleVolumeFilterTypes?: VolumeFilterTypes[];
}

export type VolumeFilter = {
  type: VolumeFilterTypes;
  startDate: Date | null;
  endDate: Date;
};

export const getFilterPeriod = (type: VolumeFilterTypes): VolumeFilter => {
  let startDate: Date | null = null;
  const today = endOfToday();

  switch (type) {
    case VolumeFilterTypes.OneWeek: {
      startDate = sub(today, {
        days: 7,
      });
      break;
    }
    case VolumeFilterTypes.FourWeeks: {
      startDate = sub(today, {
        days: 28,
      });
      break;
    }
    case VolumeFilterTypes.OneYear: {
      startDate = sub(today, {
        years: 1,
      });
      break;
    }
    case VolumeFilterTypes.MTD: {
      startDate = new Date(getYear(today), getMonth(today), 1);
      break;
    }
    case VolumeFilterTypes.QTD: {
      // get the start month of the current quarter:
      const startMonthOfQuarter = (Math.ceil((getMonth(today) + 1) / 3) - 1) * 3;
      startDate = new Date(getYear(today), startMonthOfQuarter, 1);
      break;
    }
    case VolumeFilterTypes.YTD: {
      startDate = new Date(getYear(today), 0, 1);
      break;
    }
    case VolumeFilterTypes.All: {
      startDate = null;
      break;
    }
    case VolumeFilterTypes.Custom: {
      startDate = new Date(getYear(today), getMonth(today), 1);
      break;
    }
    default:
      break;
  }

  return {
    startDate: startDate && startOfDay(startDate),
    endDate: today,
    type,
  };
};

const isVolumeFilterTypeVisible = (
  volumeFilterType: VolumeFilterTypes,
  visibleVolumeFilterTypes?: VolumeFilterTypes[]
) => {
  if (!visibleVolumeFilterTypes) {
    return true;
  }
  return visibleVolumeFilterTypes.includes(volumeFilterType);
};

export const FilterPeriodSelect = ({
  onSelect,
  selected,
  visibleVolumeFilterTypes,
}: FilterPeriodSelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const type = event.target.value as VolumeFilterTypes;
    const filter = getFilterPeriod(type);
    onSelect(filter);
  };

  const handleDateChange = (isStartDate: boolean) => (date: Date | null) => {
    if (!date) {
      return;
    }
    const filter = {
      startDate: isStartDate ? date : selected.startDate,
      endDate: !isStartDate ? date : selected.endDate,
      type: selected.type,
    };
    onSelect(filter);
  };

  return (
    <BoxCentered minWidth={250}>
      {selected?.type === VolumeFilterTypes.Custom && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography>From:</Typography>
          &emsp;
          <DatePicker
            inputFormat="MM/dd/yyyy"
            maxDate={selected.endDate}
            value={selected.startDate}
            onChange={handleDateChange(true)}
            renderInput={params => (
              <TextField variant="outlined" size="small" {...params} sx={{width: '180px'}} />
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
          &emsp;
          <Typography>To:</Typography>
          &emsp;
          <DatePicker
            inputFormat="MM/dd/yyyy"
            minDate={selected.startDate ?? getFilterPeriod(VolumeFilterTypes.MTD).startDate!}
            value={selected.endDate}
            onChange={handleDateChange(false)}
            renderInput={params => (
              <TextField variant="outlined" size="small" {...params} sx={{width: '180px'}} />
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
          &emsp;
        </LocalizationProvider>
      )}
      <TodayIcon style={{color: '#0000008a'}} />
      &emsp;
      <Select
        sx={{
          width: 250,
        }}
        size="small"
        value={selected?.type}
        label="Age"
        onChange={handleChange}
        input={<InputBase />}
      >
        {isVolumeFilterTypeVisible(VolumeFilterTypes.OneWeek, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.OneWeek}>1 WEEK</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.FourWeeks, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.FourWeeks}>4 WEEKS</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.OneYear, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.OneYear}>1 YEAR</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.MTD, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.MTD}>MTD</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.QTD, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.QTD}>QTD</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.YTD, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.YTD}>YTD</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.All, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.All}>ALL</MenuItem>
        )}
        {isVolumeFilterTypeVisible(VolumeFilterTypes.Custom, visibleVolumeFilterTypes) && (
          <MenuItem value={VolumeFilterTypes.Custom}>CUSTOM</MenuItem>
        )}
      </Select>
    </BoxCentered>
  );
};
