import {Box, Divider, Stack} from '@mui/material';
import {AgentView, Dispositions, ListOption, SearchCriteria, useApiContainer} from '@ozark/common';
import {BoxParentHeight, ButtonExportCsv, percentFormatter, Table} from '@ozark/common/components';
import {VolumeFilterTypes} from '@ozark/functions/lib/functions/express/private/types/Reports';
import {
  DispositionStatistic,
  PaginatedResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {DispositionStatisticFields} from '@ozark/functions/src/functions/express/private/types/DispositionStatistic';
import {CancelTokenSource} from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {Column} from '../../../api/Column';
import {CancelOperationMessage} from '../../../api/Constants';
import {useAgents} from '../../../hooks/useAgents';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {FilterPeriodSelect, getFilterPeriod, VolumeFilter} from '../../FilterPeriodSelect';
import {AgentSelect} from '../../Filters/AgentSelect';
import {SelectOptions} from '../../Filters/SelectOption';

export const DEFAULT_SEARCH_CRITERIA: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'asc',
  orderBy: DispositionStatisticFields.agent,
};

const DispositionStatisticOptions: {[_: string]: string} = {
  [Dispositions.asReceived]: Dispositions.asReceived,
  [Dispositions.uwApproved]: Dispositions.uwApproved,
  ['CApproved']: 'C Approved',
  [Dispositions.boarded]: 'Boarded',
  [Dispositions.uwPending]: Dispositions.uwPending,
  [Dispositions.uwDeclined]: Dispositions.uwDeclined,
  [Dispositions.uwWithdrawn]: Dispositions.uwWithdrawn,
  ['All']: 'All Disposition',
};

export const AgentStatisticsDispositionStatisticsPage = () => {
  const apiClient = useApiContainer();
  const [selectedFilter, setSelectedFilter] = useState<VolumeFilter>(
    getFilterPeriod(VolumeFilterTypes.MTD)
  );
  const [dispositionStatisticReport, setDispositionStatisticReport] =
    useState<PaginatedResponse<DispositionStatistic> | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {agents} = useAgents();
  const dispositionOptions = Object.entries(DispositionStatisticOptions).map(
    ([key, value]) => ({key, value} as ListOption)
  );
  const [disposition, setDisposition] = useState<ListOption>(dispositionOptions[0]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DEFAULT_SEARCH_CRITERIA);
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  useEffect(() => {
    setLoading(true);
    const cancelSource = apiClient?.agentStatistics.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        console.log('cancel');
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });
    apiClient?.agentStatistics
      .getDispositionStatistics(
        selectedFilter,
        searchCriteria,
        disposition.key,
        selectedAgent?.id,
        cancelSource?.token
      )
      .then(report => {
        setDispositionStatisticReport(report || null);
        setLoading(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setDispositionStatisticReport(null);
        setLoading(false);
      });
  }, [apiClient, selectedAgent, disposition, searchCriteria, selectedFilter]);

  const getAllDataForExport = useCallback(async () => {
    if (!apiClient) return [];

    const pageConfigFull: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
    const result = await apiClient?.agentStatistics.getDispositionStatistics(
      selectedFilter,
      pageConfigFull,
      disposition.key,
      selectedAgent?.id
    );
    return result?.data ?? [];
  }, [apiClient, selectedAgent, disposition, searchCriteria, selectedFilter]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const hasData = Boolean(dispositionStatisticReport && dispositionStatisticReport.data.length);
  const columns = getColumnsConfig(disposition);

  return (
    <Stack flex={1} pb={1}>
      <Box display="flex" alignItems="center">
        <Box flex={1} />
        <Box>
          <FilterPeriodSelect
            onSelect={setSelectedFilter}
            selected={selectedFilter}
            visibleVolumeFilterTypes={[
              VolumeFilterTypes.MTD,
              VolumeFilterTypes.YTD,
              VolumeFilterTypes.Custom,
              VolumeFilterTypes.All,
            ]}
          />
        </Box>
        <AgentSelect
          agents={agents}
          onAgentSelect={setSelectedAgent}
          selectedAgent={selectedAgent}
          showGroupName
          width={250}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <SelectOptions
          options={dispositionOptions}
          onOptionSelect={(value?: ListOption) => setDisposition(value ?? dispositionOptions[0])}
          selectedOption={disposition}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <ButtonExportCsv
          filename="agent-statistics-dispositions"
          rows={dispositionStatisticReport?.data}
          columnsConfig={columns}
          getRows={getAllDataForExport}
        />
      </Box>
      {!loading && hasData && (
        <BoxParentHeight my={2}>
          <Table
            columns={columns}
            data={dispositionStatisticReport!}
            onRetrieveData={handleRetrieveData}
            paginate
            stickyHeader
          />
        </BoxParentHeight>
      )}
      <LoadingStatus loading={loading || agents.promised} hasData={hasData} />
    </Stack>
  );
};

const getAllDispositionColumnsConfig = (): Column<DispositionStatistic>[] => [
  {
    id: DispositionStatisticFields.agent,
    numeric: false,
    sortable: true,
    label: 'Agent Name',
    width: 120,
    export: true,
  },
  {
    id: DispositionStatisticFields.asReceivedCount,
    numeric: true,
    sortable: true,
    label: `AS Received Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.uwReceivedCount,
    numeric: true,
    sortable: true,
    label: `UW Received Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.boardedCount,
    numeric: true,
    sortable: true,
    label: `Boarded Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.uwCApprovedCount,
    numeric: true,
    sortable: true,
    label: `C Approved Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.uwPendingCount,
    numeric: true,
    sortable: true,
    label: `UW Pending Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.uwDeclinedCount,
    numeric: true,
    sortable: true,
    label: `UW Declined Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.uwWithdrawnCount,
    numeric: true,
    sortable: true,
    label: `UW Withdrawn Count`,
    export: true,
  },
];

const getParticularDispositionColumnsConfig = (
  disposition: ListOption
): Column<DispositionStatistic>[] => [
  {
    id: DispositionStatisticFields.agent,
    numeric: false,
    sortable: true,
    label: 'Agent Name',
    width: 120,
    export: true,
  },
  {
    id: DispositionStatisticFields.count,
    numeric: true,
    sortable: true,
    label: `${disposition.value} Count`,
    export: true,
  },
  {
    id: DispositionStatisticFields.countOfTotal,
    numeric: true,
    sortable: true,
    label: `Count % of Total ${disposition.value}`,
    selector: row => percentFormatter.format(row.countOfTotal),
    export: true,
  },
  {
    id: DispositionStatisticFields.volume,
    numeric: true,
    sortable: true,
    label: `${disposition.value} Volume`,
    export: true,
  },
  {
    id: DispositionStatisticFields.volumeOfTotal,
    numeric: true,
    sortable: true,
    label: `Volume % of Total ${disposition.value}`,
    selector: row => percentFormatter.format(row.volumeOfTotal),
    export: true,
  },
];

const getColumnsConfig = (disposition: ListOption): Column<DispositionStatistic>[] =>
  disposition.value === 'All Disposition'
    ? getAllDispositionColumnsConfig()
    : getParticularDispositionColumnsConfig(disposition);
